
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  onBeforeMount,
  getCurrentInstance,
  ComponentInternalInstance,
  createVNode
} from 'vue'
import pagaTable from '@/components/pagination-table/index'
import { TransformCellTextProps } from 'ant-design-vue/lib/table/interface'
import formSearch from '@/components/page-search/form'
import popupWindow from './popupNew.vue'
import { menuPage, menu_del, menuTree } from '@/api/system'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'

const purchaseColumns = [
  {
    title: '菜单名称 ',
    dataIndex: 'menuName'
  },

  {
    title: '组件名称',
    dataIndex: 'component'
  },
  {
    title: '菜单图标',
    dataIndex: 'icon'
  },
  {
    title: '排序',
    dataIndex: 'sort'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    slots: { customRender: 'operation' }
  }
]
export default defineComponent({
  name: 'questionType',
  components: {
    pagaTable,
    popupWindow,
    formSearch
  },
  setup(props: any) {
    const { proxy } = getCurrentInstance() as ComponentInternalInstance

    const data = reactive({
      purchaseColumns,
      selectedRowKeys: [],
      extraModel: {},
      formBtn: {
        colProps: { span: 16 },
        formItemProps: {
          wrapperCol: {
            span: 24
          }
        }
      }
    })
    const onSelectChange = (selectedRowKeys: never[]) => {
      data.selectedRowKeys = selectedRowKeys
    }
    const table = ref()
    const getDataApi = (params: any) => {
      return new Promise((reslove) => {
        menuTree({}).then((res) => {
          reslove(res)
        })
      })
    }
    const handleChange = (
      pagination: any,
      filter: any,
      sorter: any,
      { currentDataSource }: any
    ) => {
      console.log(pagination, filter, sorter, currentDataSource)
    }
    const handleClick = (text: string, row: any) => {
      Title.value = '编辑菜单'
      popupWindow.value.showModal(row)
    }
    const formSearch = ref()
    const popupWindow = ref()
    const popupRandom = ref()
    const Title = ref()
    const updatedTime = ref([])
    const handleAdd = () => {
      Title.value = '新增菜单'
      popupWindow.value.showModal()
    }
    const handleSearch = (formState: any) => {
      // formSearch.value.getQuery();
      // console.log({ ...formSearch.value.getQuery() });
      // console.log("插槽", { ...updatedTime.value });
      table.value.refresh()
    }
    const randomClick = () => {
      Title.value = '随机组卷'
      popupRandom.value.showModal()
    }
    const refresh = () => {
      table.value.refresh()
      data.selectedRowKeys = []
    }

    const visible = ref<boolean>(false)
    const questions = ref([] as any[])
    const del = (val: any) => {
      menu_del({ id: val.menuId }).then((res) => {
        table.value.refresh()
      })
    }

    return {
      questions,
      visible,
      del,
      refresh,
      randomClick,
      ...toRefs(data),
      table,
      onSelectChange,
      getDataApi,
      handleChange,
      handleClick,
      formSearch,
      popupWindow,
      popupRandom,
      Title,
      handleAdd,
      handleSearch,
      updatedTime
    }
  }
})
